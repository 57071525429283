import { EmailValidation, BannedWordsValidation } from '../../utils/formValidations.js';
import emailContactSender from '../../services/emailContactSender.js';
import { useState } from 'react';
import '../../styles/ContactForm.css';

export default function ContactForm() {

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => {
        const name = e.target.value;
        const maxMessageLength = 20;
        if (name.length > maxMessageLength) {
            return
        }

        const regex = /^[a-zA-Z\s]*$/;
        if (regex.test(name)) {
            setName(name);
        }

    }

    const handleSurnameChange = (e) => {
        const surname = e.target.value;
        const maxMessageLength = 20;
        if (surname.length > maxMessageLength) {
            return
        }

        const regex = /^[a-zA-Z\s]*$/;
        if (regex.test(surname)) {
            setSurname(surname);
        }
    }

    const handlePhonenumberChange = (e) => {
        const phonenumber = e.target.value;

        setPhonenumber(phonenumber);
    }

    const handleEmailChange = (e) => {
        const email = e.target.value;
        if (EmailValidation({ email })) {
            setEmail(email);
        }
    }


    const handleMessageChange = (e) => {
        const message = e.target.value;
        console.log(message);

        if (!BannedWordsValidation({ message })) {
            setMessage(message);
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && surname && phonenumber && email && message) {
            alert('Gracias por Contactarnos!! En la brevedad nos pondremos en contacto con usted.');
            emailContactSender({ name, surname, phonenumber, email, message });
        }
    };

    return (
        <form id='contact-form' className='contact-form' onSubmit={handleSubmit}>
            <ul>
                <li>
                    <label for="name_input">Nombre</label>
                    <input type="text" id="name_input" name="user_name" placeholder='Escriba su nombre.' required onChange={handleNameChange} />
                </li>
                <li>
                    <label for="surname_input">Apellido</label>
                    <input type="text" id="surname_input" name="user_surname" placeholder='Escriba su apellido.' required onChange={handleSurnameChange} />
                </li>
                <li>
                    <label for="phone_input">Telefono</label>
                    <input type="text" id="phone_input" name="user_phone" placeholder='Escriba su numero de telefono.' required onChange={handlePhonenumberChange} />
                </li>
                <li>
                    <label for="email_input">E-mail</label>
                    <input type="email" id="email_input" name="user_email" placeholder='Escriba su email.' required onChange={handleEmailChange} />
                </li>
                <li className='li_msg'>
                    <label for="msg_input">Mensaje</label>
                    <textarea id="msg_input" name="user_msg" placeholder='Escriba su mensaje.' required onChange={handleMessageChange}></textarea>
                </li>
            </ul>

            <button type="submit" id="submit-btn">Enviar Mensaje</button>
        </form>
    )
}