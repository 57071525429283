import services from '../../config/services.json';
import '../../styles/ServicesOverviews.css'
export default function ServicesOverviews() {
    const servicesList = services.map((service, index) => {
        return (
            <li key={index}>
                <img className='service-icon' src={service.imageURL} alt={service.name} />
                <h3 className='service-title'>{service.name}</h3>
                <p className='service-description'>{service.description}</p>
            </li>
        );
    });

    return (
        <section className='services-overviews'>
            <h2 className='services-overviews-title'>Por estas razones, somos tu mejor elección</h2>
            <ul>
                {servicesList}
            </ul>
        </section >
    )
}