import socialmedia from '../../config/socialmedia.json';
import '../../styles/Header.css';

export default function Header() {
    return (
        <header id='header-app'>
            <h1 id='BTlogo'>
                <a href="https://www.mutualbuonaterra.com.ar" >
                    <img src="/img/BTlogo.png" alt="Mutual Buona Terra" />
                </a>
            </h1>
            <h2>La solucion que buscas.</h2>

            <div className="socialmedia">
                <a href={socialmedia.facebook} target="_blank" rel="noopener noreferrer">
                    <img src="/img/network/facebook.png" alt="facebook" />
                </a>
                <a href={socialmedia.instagram} target="_blank" rel="noopener noreferrer">
                    <img src="/img/network/instagram.png" alt="instagram" />
                </a>
            </div>
        </header>
    );
}


