import { useEffect, useState } from 'react';
import homeImages from '../../config/homeimages.json';
import '../../styles/Carrousel.css';

export default function Carrousel() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const carrouselImages = homeImages.map((img, index) => {
        return (
            <li key={img.url} className={`carousel-image ${currentImageIndex === index ? 'active' : ''}`}>
                <div className='img-container'>
                    <img className="home-image" src={img.url} alt={img.alt} />
                </div>
            </li>

        );
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % homeImages.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [currentImageIndex]);

    return (
        <section className="carrousel-home">
            <ul>
                {carrouselImages}
            </ul>
        </section>
    )
}