import socialmedia from '../../config/socialmedia.json';
import ContactForm from '../ContactForm/ContactForm.jsx';
import '../../styles/Footer.css';

export default function Footer() {

    return (
        <footer>
            <div className='left'>
                <h2>Contactanos</h2>
                <h3>Complete el formulario y nuestro equipo le respondera a la brevedad</h3>

                <div id='footer-info'>
                    <p className='contact_hours'>Lunes a viernes de 9:00 a 15:00 Hs.</p>
                    <a href={socialmedia.whatsapp} className='contact_phone' target="_blank" rel="noopener noreferrer">+54 9 223 <strong>6 90-5908</strong> </a>
                    <a href={socialmedia.email} className='email'>contacto@mutualbuonaterra.com.ar</a>

                    <div className="socialmedia">
                        <a href={socialmedia.facebook} target="_blank" rel="noopener noreferrer">
                            <img src="/img/network/facebook.png" alt="facebook" />
                        </a>
                        <a href={socialmedia.instagram} target="_blank" rel="noopener noreferrer">
                            <img src="/img/network/instagram.png" alt="instagram" />
                        </a>
                    </div>

                    <div id="copyright">
                        Copyright © 2024 <strong>MUTUAL BUONA TERRA</strong> Todos los derechos reservados.
                    </div>
                </div>
            </div>

            <div className='right'>
                <ContactForm />
            </div>
        </footer>
    )
}


