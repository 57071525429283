import './styles/App.css';
import RegisterFloat from './components/Floats/RegisterFloat.jsx';
import WhatsappFloat from './components/Floats/WhatsappFloat.jsx';
import Header from './components/Header/Header.jsx';
import Carrousel from './components/Carrousel/Carrousel.jsx';
import ServicesOverviews from './components/Overviews/ServicesOverviews.jsx';
import InstagramSection from './components/Instagram/InstagramSection.jsx';
import CompaniesSection from './components/Companies/CompaniesSection.jsx';
import Footer from './components/Footer/Footer.jsx';


function App() {

    return (
        <div className="App">
            <RegisterFloat ></RegisterFloat>
            <WhatsappFloat ></WhatsappFloat>
            <Header ></Header>
            <Carrousel ></Carrousel>
            <ServicesOverviews ></ServicesOverviews>
            <InstagramSection ></InstagramSection>
            <CompaniesSection ></CompaniesSection>
            <Footer ></Footer>
        </div>
    );
}

export default App;
