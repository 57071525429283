// const APIURL = 'http://localhost:4000/BT';
const APIURL = 'https://www.mutualbuonaterra.com.ar/BT';

const emailContactSender = async ({ name, surname, phonenumber, email, message }) => {
    console.log('try to send email: ', { name, surname, phonenumber, email, message })
    try {
        const response = await fetch(`${APIURL}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                surname,
                phonenumber,
                email,
                message
            })
        });
        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error(error);
        console.log('Error sending email: ' + error);
    }
}


export default emailContactSender
