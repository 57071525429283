import companies from '../../config/companies.json';
import '../../styles/CompaniesSection.css';

export default function CompaniesSection() {

    const listCompanies = companies.map((company, index) => {
        return (
            <li className='company-icon-container' key={index}>
                <img className={company.name} src={company.imageURL} alt={company.name} />
            </li>
        );
    });

    return (
        <div className='companies-container'>
            <h2 className='companies-title' >Empresas que nos respaldan</h2>
            <ul>
                {listCompanies}
            </ul>
        </div>
    )

}



