import socialmedia from '../../config/socialmedia.json';
import '../../styles/Floats.css';

export default function WhatsappFloat() {
    return (
        <div className='float-whatsapp'>
            <a href={socialmedia.whatsapp} target="_blank" rel="noopener noreferrer">
                <img src="/img/network/whatsapp.png" alt="whatsapp" />
            </a>
        </div>
    );
}