function NameValidation({ name }) {
    const regex = /^[a-zA-Z\s]*$/;
    return regex.test(name);
}

function PhonenumberValidation({ phonenumber }) {
    const regex = /^[+\d]?[(]?\d{1,4}[)]?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    return regex.test(phonenumber);
}

function EmailValidation({ email }) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}

function BannedWordsValidation({ message }) {
    const bannedWords = ['hacker', 'vandal', 'cyber', 'puto', 'gay', 'chorro', 'chorros', 'gay'];
    const lowerCaseMessage = message.toLowerCase();
    return bannedWords.some((word) => lowerCaseMessage.includes(word));
}

export { NameValidation, PhonenumberValidation, EmailValidation, BannedWordsValidation };