import forms from '../../config/forms.json';
import '../../styles/Floats.css';

export default function RegisterFloat() {
    return (
        <div className='float-register'>
            <a href={forms.inscription} target="_blank" rel="noopener noreferrer">
                <img src="/img/quiero_asociarme.png" alt="Quiero Asociarme" />
            </a>
        </div>
    );
}