import React, { useState, useEffect } from 'react';
import '../../styles/InstagramSection.css';
import post from '../../config/instagramPost.json';

export default function InstagramSection() {
    // const [posts, setPosts] = useState([]);
    // const accessToken = 'IGQWRPTEhPMFZArc1pCNWg2ZAk9KMVhQWVZAlc0F1aXhjeHBIeVhEa1luV0kzMlA2QmJkUWZA2SzRfZA3ExWko5SUhadUNfc05vTy1CX3p2MWJud09vbTlZAeHlEMUo0WmhzTm9BcS1UMTAxX1JYSnZAPUWt4NG9kNTU3OEUZD';
    // const userId = '8681174805333111';

    // const fetchInstagramPosts = async () => {
    //     try {
    //         const response = await fetch(
    //             `https://graph.instagram.com/${userId}/media?fields=id,media_url,caption,media_type,permalink&access_token=${accessToken}`
    //         );

    //         if (!response.ok) {
    //             throw new Error('Error fetching data from Instagram API');
    //         }

    //         const APIdata = await response.json();
    //         setPosts(APIdata.data.slice(0, 3));
    //     } catch (error) {
    //         console.error('Error fetching Instagram posts:', error);
    //     }
    // };

    useEffect(() => {
        // fetchInstagramPosts();
    }, []);

    return (
        <section className='instagram-section'>
            <h2 className='instagram-title'>Siguenos en Instagram</h2>

            <div className='instagram-container' >
                {
                    post.map((post, index) => {
                        return (
                            <a href={post.url} className='instagram-post' target="_blank" rel="noopener noreferrer" key={index}>
                                <iframe
                                    key={post.key}
                                    src={post.src}
                                    title={post.title}
                                    allowTransparency="true"
                                    frameborder="0"
                                    scrolling="no"
                                ></iframe>
                            </a>
                        );
                    })
                }

            </div>
        </section>
    )
}







